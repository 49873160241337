import * as React from 'react'
import { PageProps, Link } from 'gatsby'
import { Layout } from '../../components/default-layout'
import { SEO } from '../../components/seo'
import { Contact } from '../../components/page'
import { SpecificLogo } from '../../components/logo'
import { Slider } from '../../components/slider'
import { DefaultContainer } from '../../components/containers'

export const Head = () => {
  return (
    <SEO
      article={false}
      title={'Apertura de vehículos a domicilio'}
      description={
        'Servicio de cerrajería automotriz a domicilio las 24 horas del día en Bogotá. Apertura de vehículos de alta, media y baja gama.'
      }
    />
  )
}

const Text: React.FC<{ children: string | React.ReactNode }> = ({
  children,
}) => <p className={'display-block mb-3'}>{children}</p>

const Page: React.FC<PageProps> = () => {
  return (
    <Layout
      theme={'yellow'}
      visible={{}}
      logo={<SpecificLogo>Cerrajería</SpecificLogo>}
    >
      <Slider
        id={'inicio'}
        wallpaper={'unlockCar'}
        overlay={0.8}
        business={'Cerrajero automotriz'}
        pageTitle={'Cerrajería automotriz'}
        pageDescription={
          <span className={'text-white hidden md:inline-block'}>
            Ofrecemos el servicio de cerrajería automotriz en minutos.
            Trabajamos con todas marcas: BMW, Audi, Mercedes Benz, Renault,
            Mazda, Hyundai, Kia, Nissan, Volkswagen, Toyota, Chevrolet, Citroen,
            Fiat, Ford, Honda, Jeep.
          </span>
        }
        subTitle={'Cerrajería las 24 horas del día'}
        timing={`Aproximadamente a ${
          Math.floor(Math.random() * 15) + 15
        } minutos de tu ubicación.`}
        goTo={'#servicios'}
      />

      <DefaultContainer>
        <section id="servicios" className={'my-10'}>
          <h1 className={'text-3xl mb-4 font-bold'}>Apertura de vehículos</h1>
          <Text>
            ¿Las llaves de tu vehículo se han perdido? ¿Te las han robado? o ¿De
            alguna manera se han dañanado y la puerta de tu vehículo no abre? No
            te preocupes, esta situación es mucho más común de lo que crees. En
            Domicilios 24/7 hacemos todo lo posible para brindar servicios de{' '}
            <strong>cerrajería automotriz</strong> profesionales, confiables,
            rápidos y asequibles. Si te has quedado fuera de tu vehículo, llamá
            a nuestra línea de atención al cliente para obtener asistencia
            inmediata. Describe tu situación a nuestros asesores, solicita el
            costo del servicio, luego asignaremos un{' '}
            <strong>cerrajero experto</strong>; con herramientas especiales, que
            se dirigirá de manera rápida al lugar donde te encuentres. Nuestro
            servicio de <Link to={'/cerrajeria/'}>cerrajería</Link> automotriz a
            domicilio utiliza tecnología de punta, además, hemos seleccionado y
            capacitado a un equipo de{' '}
            <strong>cerrajeros de alta calidad</strong> con el fin de brindar la
            mejor experiencia a nuestros clientes. Esperamos que este servicio
            supere tus expectativas.
          </Text>
          <h3 className={'text-xl mb-3 mt-3 font-bold'}>
            ¿Tu hijo está dentro del vehículo?
          </h3>
          <Text>
            Esta situación puede llegar a ser perturbadora. Son accidentes que
            pueden suceder, te das la vuelta por solo una fracción de segundo y
            la puerta del vehículo se cierra de golpe con las llaves adentro.
            Desafortunadamente, para muchos padres esto sucede con un niño
            pequeño o un bebé adentro. En ese caso, puedes sentir pánico, pero
            todo lo que necesitas hacer es llamarnos. En Domicilios 24/7 nos
            preocupamos por nuestros clientes. Nuestra línea de atención al
            cliente está perfectamente capacitada para obtener la información
            vital y necesaria para enviar a nuestro cerrajero rápidamente a
            liberar a tu hijo. Si no puedes acceder a tu billetera o a tu
            tarjeta de crédito y necesitas el servicio de inmediato, no te
            preocupes, estamos a solo una llamada telefónica de distancia.
          </Text>
        </section>
      </DefaultContainer>

      <Contact
        id={'contacto'}
        business={'HardwareStore'}
        businessName={'Ferretería'}
        priceRange={"$10.000 - $12'000.000"}
      />
    </Layout>
  )
}

export default Page
